<i18n>
{
  "fi": {
    "oma": "Jipii! Valitsit oman kampuksen<br>Oman kampuksen sivulle voit valita mitkä tahansa ravintolat mihin tahansa järjestykseen. Aloita painamalla järjestysnappulaa yllä."
  },
  "en": {
    "oma": "Jipii! You chose a custom campus<br>On this custom campus view you may choose whatever restaurants you want and even order them to your liking. Start by pressing the re-ordering icon above."
  }
}
</i18n>
<template>
  <transition
    appear
    appear-class="campus-appear"
    appear-to-class="campus-appear-to"
    appear-active-class="campus-appear-active"
  >
    <!-- DO NOT REMOVE THIS! Needed for the stacking context to work properly -->
    <div style="">
      <div class="container mb-4 campus">
        <div class="d-flex col-md-4" style="margin-bottom:1.75rem;">
          <div class="flex-grow-1">
            <h2>{{ tuniKampus(campus) }}</h2>
          </div>
          <div
            class="d-flex align-items-center trigger"
            style="cursor: pointer;"
          >
            <i @click="toggleOrganizer">
              <img
                class="organizer"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHeSURBVGhD7dm/S8NAHAXwuIggom7iKII/VgUXwcXFVVBB0L9BcBbEzUlEEQTBVRwVXAQXBxd3XQQRHFykij9A/PVebDBcr5c05r5JzD34QJNLaF7aa0LjubiUM51wArv+UkHDEufwBWdcETfcUVob6MKxoMQFdEHscCdpp6DmTyUY7vgJ9wIeQVckXOIaBqrrAh0QGe5c+XlpPaOgFmmHoIQJj3EVWkAbbvQA4TNgywTw/cJFmuEQggN+BfWTZIlg/AiaoCbBBpLUr1a4zB0MghquuwFuM8kVatT2EnhW1cQpswgcX/eXcpyoMrPAsS1/KedhmQPgAfNnOBxjkZ4MdIMpLLMJK/7Sb4xFOCBNd0GMk8giH3Al4BasFsnygthIylOE90C6SZm2abBaRJqVIrxhk7YDSWIsUqSUo8hQBvogScox2TnwDrpJmbZLsFrEXdkbjCtSTWSRJxgXsABWi0izUuQ4A2uQJMYiRUo5iugmpW3DkCTlmOwceIN9AXwKZbVIUS6Ic/AvimwD91/2l5TkpcgYTNUxAxvAu/QX6IWa5KHICHB9FD47mQdtuMEz6M5E2paA76cWaQX+16v7gaA94NOqfqibcGMpSeeIMbozYJv6L7uLi4uLKZ73DY6xi9sJ1zHpAAAAAElFTkSuQmCC"
              />
            </i>
          </div>
        </div>

        <div
          v-if="showCustomCampusInfo"
          class="alert alert-primary alert-dismissible"
        >
          <span v-html="$t('oma')" />
          <button
            @click="hideMsg"
            type="button"
            class="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <transition name="organizer">
          <RestaurantOrganizer v-if="showOrganizer" />
        </transition>

        <!-- <transition-group :duration="500" tag="div" class="row m-0"> -->
        <div class="row m-0">
          <template v-for="restaurant in orderedData">
            <Restaurant :key="restaurant.res" :restaurant="restaurant" />
          </template>
        </div>
        <!-- </transition-group> -->
      </div>
    </div>
  </transition>
</template>

<script>
import { gaVueFire, gaButton } from '../ga.js';
import { eiExternal, icoJarjesta } from '../icons.js';

import Restaurant from './Restaurant.vue';

export default {
  components: {
    Restaurant,
    RestaurantOrganizer: () =>
      import(/* webpackChunkName: 'Organizer' */ './Organizer.vue'),
  },
  data() {
    return {
      showOrganizer: false,
      jarjestinOffsetObject: { marginTop: '0px !important' },
      joffsetOriginal: -400,
      joffset: -400,
      icoJarjesta,
      eiExternal,
      transitionFromLeftToRight: true,
    };
  },

  mounted() {
    gaVueFire(this.$store.state.prefs);
  },

  computed: {
    campus() {
      return this.$store.state.prefs.campus.toUpperCase();
    },
    currentData() {
      return this.$store.getters.currentData;
    },
    orderedData() {
      const hide = this.$store.state.prefs.hideWholeRes;
      return this.$store.getters.currentOrder
        .filter(res => {
          if (!hide) return true;
          return !this.$store.getters.isResHidden(res);
        })
        .map(key => this.currentData[key])
        .filter(elem => !!elem);
    },
    showCustomCampusInfo() {
      return (
        this.campus === 'OMA' && !this.$store.state.prefs.hideCustomCampusMsg
      );
    },
  },
  methods: {
    tuniKampus: function(kampus) {
      return {
        TTY: 'Hervanta (TTY)',
        TAY: 'Keskusta (TAY)',
        TAYS: 'TAYS',
        OMA: 'OMA',
        TAMK: 'TAMK',
      }[kampus];
    },
    toggleOrganizer: function() {
      if (!this.showOrganizer) {
        this.showOrganizer = true;
        gaButton('JARJ');
      } else {
        this.joffset = this.joffsetOriginal;
        setTimeout(
          (function(that) {
            return function() {
              that.showOrganizer = false;
            };
          })(this),
          300
        );
      }
    },
    hideMsg() {
      this.$store.commit('HIDE_CUSTOM_CAMPUS_INFO_MSG');
    },
  },
};
</script>
<style scoped>
h2 {
  background: var(--campus-name-bg-color);
  clear: both;
  position: relative;
  color: #fff !important;
  font-size: 1.6em;
  padding: 8px 20px;
  margin: 0 0 0 -2.2rem;
  font-weight: inherit;
  line-height: 1.2;
}

h2:before {
  content: ' ';
  display: block;
  width: 0;
  height: 0;
  bottom: -10px;
  left: 0;
  z-index: -1;
  position: absolute;
  border-top: 10px solid maroon;
  border-left: 10px solid transparent;
  background: transparent;
  opacity: 0.99;
}

.container.campus {
  background: var(--content-bg-color);
  box-shadow: 3px 3px 8px 0px var(--content-shadow-color);
  margin-top: 1rem;
  padding-top: 1rem;
}

@media screen and (max-width: 999px) {
  .container.campus {
    width: auto;
    max-width: 100%;
    margin-left: 0.7rem;
    margin-right: 0.7rem;
  }
}

.organizer-enter,
.organizer-leave-to {
  max-height: 0;
}

.organizer-enter-active {
  transition: max-height 0.25s ease-in;
  overflow: hidden;
}
.organizer-leave-active {
  transition: max-height 0.2s ease-out;
  overflow: hidden;
}

.organizer-enter-to,
.organizer-leave {
  max-height: 500px;
}

.campus-appear {
  opacity: 0;
}
.campus-appear-to {
  opacity: 1;
}
.campus-appear-active {
  transition: opacity 1s;
}
.trigger img {
  max-height: 1.65rem;
}

.organizer {
}
</style>
